<script setup lang="ts">
import type { EditorialSocialWallProps } from './EditorialSocialWall.props'

const props = defineProps<EditorialSocialWallProps>()
const lsId = computed(() => `ls-${props.id}`)
const container = ref<HTMLElement | null>(null)
const { onLoaded } = useLiveStoryScript(container)
onLoaded(() => {
  // @ts-ignore
  const LiveStory = window.LiveStory
  // @ts-ignore
  window.fpls_load = window.fpls_load || []
  // @ts-ignore
  window.fpls_load.push(function () {
    // @ts-ignore
    window[`ls_${props.id}`] =
      // @ts-ignore
      window[`ls_${props.id}`] ||
      new LiveStory(lsId.value, { type: props.type })
  })
})

const { language, country } = useRouteHelper()
const dataLang = computed(() => `${language}_${country.toUpperCase()}`)
</script>

<template>
  <div
    :id="lsId"
    ref="container"
    :data-id="id"
    :data-store="dataLang"
    :data-lang="dataLang"
  ></div>
</template>
